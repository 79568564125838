export const agentsCreatedAt = {
  "david@atoshipping.com": "2024-06-01T00:00:00",
  "laura@atoshipping.com": "2024-06-01T00:00:00",
  "vu@atoshipping.com": "2024-06-01T00:00:00",
  "izzy@atoshipping.com": "2024-06-01T00:00:00",
  "david@dshglobal.com.au": "2020-02-01T00:00:00",
  "andrea@atoshipping.com": "2024-06-01T00:00:00",
  "General Manager": "2024-06-01T00:00:00",
  "k.erlacher-boeheim@kr-tm.com": "2023-03-01T00:00:00",
  "alexander.krauss@logsped.com": "2020-02-01T00:00:00",
  "jonna.ahkila-niemi@ahalogistics.com": "2020-10-05T00:00:00",
  "riikka.harju@ahalogistics.com": "2020-10-05T00:00:00",
  "katja.alatorvinen@ahalogistics.com": "2020-10-05T00:00:00",
  "emma.haapala@ahalogistics.com": "2020-10-05T00:00:00",
  "teija.vuorinen@ahalogistics.com": "2020-10-05T00:00:00",
  "ella.haapala@ahalogistics.com": "2020-10-05T00:00:00",
  "hennamaija.ritola@ahalogistics.com": "2020-10-05T00:00:00",
  "suoma.magnus@ahalogistics.com": "2020-10-05T00:00:00",
  "anne.helin@ahalogistics.com": "2020-10-05T00:00:00",
  "katariina.viertola@ahalogistics.com": "2020-10-05T00:00:00",
  "mariac@apa-air.be": "2020-02-01T00:00:00",
  "jennifer@alienlogistics.com": "2024-01-01T00:00:00",
  "gavin@alienlogistics.com": "2024-01-01T00:00:00",
  "annej@globeflex.be": "2020-02-01T00:00:00",
  "cs2@audazglobal.com": "2023-02-01T00:00:00",
  "oversea.agent-cam@greendragonlog.com.vn": "2024-03-01T00:00:00",
  "mmedjkane@cargonizer.ca": "2024-02-01T00:00:00",
  "srichard@jonesintl.ca": "2023-07-01T00:00:00",
  "SANDRA.MARTINS@ALLWAYSVCARGO.COM": "2020-02-01T00:00:00",
  "CARLOS.MANUEL@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "CARLOS.FREITAS@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "PEDRO.CRUZ@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "CARLA.BRITO@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "l.gomz@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "grace@apexintl.co.jp": "2022-09-07T00:00:00",
  "miyama@apexintl.co.jp": "2022-09-07T00:00:00",
  "michael.oh@bflogis.com": "2020-02-01T00:00:00",
  "nicholas@atraxlogistics.com": "2020-02-01T00:00:00",
  "monikatunb@wecanintl.com": "2023-03-01T00:00:00",
  "nadiya@atraxlogistics.com": "2020-02-01T00:00:00",
  "danielle@atraxlogistics.com": "2020-02-01T00:00:00",
  "selma@audazglobal.com": "2023-02-01T00:00:00",
  "daniel@audazglobal.com": "2023-02-01T00:00:00",
  "oliviaoush@wecanintl.com": "2023-03-01T00:00:00",
  "dianayaosh@wecanintl.com": "2023-03-01T00:00:00",
  "tonychu@bflogis.com": "2020-02-01T00:00:00",
  "shirley.wong@helka.com.hk": "2020-02-01T00:00:00",
  "n.carnota@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "cathy@bflogis.com": "2020-02-01T00:00:00",
  "Echo.zou@bflogis.com": "2020-02-01T00:00:00",
  "Jessica.chen@bflogis.com": "2020-02-01T00:00:00",
  "bflop1@bflogis.com": "2020-02-01T00:00:00",
  "szaccount@bflogis.com": "2020-02-01T00:00:00",
  "eyan@bflogis.com": "2020-02-01T00:00:00",
  "importsea@bflogis.com": "2020-02-01T00:00:00",
  "air@bflogis.com": "2020-02-01T00:00:00",
  "account@bflogis.com": "2020-02-01T00:00:00",
  "nylee@bflogis.com": "2020-02-01T00:00:00",
  "vicente.salazar@bonanzalogistics.com.mx": "2023-11-01T00:00:00",
  "rogerio.martinez@bonanzalogistics.com.mx": "2023-11-01T00:00:00",
  "esmeralda.castillo@bonanzalogistics.com.mx": "2023-11-01T00:00:00",
  "george@oceaniclogistics.com": "2023-06-01T00:00:00",
  "a.encarnacion@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "m.perez@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "haytham@clg-eg.com": "2020-02-01T00:00:00",
  "tiina.haapala@ahalogistics.com": "2020-10-05T00:00:00",
  "e.lefaucheur@go2bba.com": "2020-11-06T00:00:00",
  "g.lemaitte@go2bba.com": "2020-11-06T00:00:00",
  "t.b.a": "2023-07-01T00:00:00",
  "alexander.bauermann@talogis.de": "2020-02-01T00:00:00",
  "stephan.wunderwald@talogis.de": "2020-02-01T00:00:00",
  "a.martin@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "w.becker@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "l.alonso@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "osmulska@cargonizer.ca": "2024-02-01T00:00:00",
  "souazene@cargonizer.ca": "2024-02-01T00:00:00",
  "yharfouche@cargonizer.ca": "2024-02-01T00:00:00",
  "alexander.theuerkorn@talogis.de": "2020-02-01T00:00:00",
  "kshithijaya.nanayakkara@cfglobal.co": "2023-08-01T00:00:00",
  "thida.srivichienchai@cfglobal.co": "2023-08-01T00:00:00",
  "geoffry.brandon@cfglobal.co": "2023-08-01T00:00:00",
  "alexander.kuex@talogis.com": "2020-02-01T00:00:00",
  "frank.lisser@neelevat.com": "2020-02-01T00:00:00",
  "v.boulieris@customs-solution.com.gr": "2024-10-01T00:00:00",
  "pradeep@htllogistics.com": "2022-01-25T00:00:00",
  "vikas@htllogistics.com": "2022-01-25T00:00:00",
  "sue@dshglobal.com.au": "2020-02-01T00:00:00",
  "george@dshglobal.com.au": "2020-02-01T00:00:00",
  "julia@dshglobal.com.au": "2020-02-01T00:00:00",
  "dominic@dshglobal.com.au": "2020-02-01T00:00:00",
  "kev@uefl.ie": "2024-05-15T00:00:00",
  "amit@htllogistics.com": "2022-01-25T00:00:00",
  "chandana@htllogistics.com": "2022-01-25T00:00:00",
  "derya@cosulich.com.tr": "2020-04-13T00:00:00",
  "elif@cosulich.com.tr": "2020-04-13T00:00:00",
  "cemile@cosulich.com.tr": "2020-04-13T00:00:00",
  "beran@cosulich.com.tr": "2020-04-13T00:00:00",
  "vp_sales@rcubelogistics.com": "2023-04-01T00:00:00",
  "harpreetsingh@speditionindia.com": "2023-08-01T00:00:00",
  "darren@uefl.ie": "2024-05-15T00:00:00",
  "brittv@globeflex.be": "2020-02-01T00:00:00",
  "Ran.Tuvia@MasterCargo.co.il": "2024-08-01T00:00:00",
  "jacky@jackylines.com": "2020-05-18T00:00:00",
  "l.trojani@sinergy-italy.com": "2020-02-01T00:00:00",
  "steve@apexintl.co.jp": "2022-09-07T00:00:00",
  "sharon.ki@helka.com.hk": "2020-02-01T00:00:00",
  "derek.chu@helka.com.hk": "2020-02-01T00:00:00",
  "kelvin.leung@helka.com.hk": "2020-02-01T00:00:00",
  "dennis.chan@helka.com.hk": "2020-02-01T00:00:00",
  "samson.chan@helka.com.hk": "2020-02-01T00:00:00",
  "cedric@htllogistics.com": "2022-01-25T00:00:00",
  "dinesh@htllogistics.com": "2022-01-25T00:00:00",
  "judynjeri@tmmlogistics.co.ke": "2020-06-15T00:00:00",
  "sales@log-net.lt": "2020-02-01T00:00:00",
  "kaizen@alienlogisitcs.com": "2024-01-01T00:00:00",
  "penny@crlogistics.com.my": "2021-05-01T00:00:00",
  "kaijin@sealandglobal.com.my": "2024-04-01T00:00:00",
  "rodrigo.nunez@bonanzalogistics.com.mx": "2023-11-01T00:00:00",
  "m.espinosa@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "dkurabieski@imperativelogistics.com": "2020-02-01T00:00:00",
  "wmeder@imperativelogistics.com": "2020-02-01T00:00:00",
  "albert@jackylines.com": "2020-05-18T00:00:00",
  "abass@aribassglobal.com": "2023-02-01T00:00:00",
  "info@warecareinternational.com": "2024-08-01T00:00:00",
  "r.erlacher@kr-tm.com": "2023-03-01T00:00:00",
  "m.decaires@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "clemaire@legendre.fr": "2023-10-05T00:00:00",
  "rmontel@legendre.fr": "2023-10-05T00:00:00",
  "tngo@legendre.fr": "2023-10-05T00:00:00",
  "manila@networxglobal.net.ph": "2020-08-20T00:00:00",
  "migs.cabreros@networxglobal.net.ph": "2020-08-20T00:00:00",
  "ewelina@ocg.com": "2020-02-01T00:00:00",
  "sales@ls-chartering.ro": "2020-02-01T00:00:00",
  "ops@ls-chartering.ro": "2020-02-01T00:00:00",
  "agency@ls-chartering.ro": "2020-02-01T00:00:00",
  "tomas@log-net.lt": "2020-02-01T00:00:00",
  "ELSA.ALMEIDA@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "alexandra.pucher@logsped.com": "2020-02-01T00:00:00",
  "sophie.geng@logsped.com": "2020-02-01T00:00:00",
  "andreas.mohr@logsped.com": "2020-02-01T00:00:00",
  "markus.baldauf@logsped.com": "2020-02-01T00:00:00",
  "andreas.friese@logsped.com": "2020-02-01T00:00:00",
  "johannes.botan@logsped.com": "2020-02-01T00:00:00",
  "PEDRO.SOUSA@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "tonny.yeh@naffreight.com": "2022-01-02T00:00:00",
  "peter.li@naffreight.com": "2022-01-01T00:00:00",
  "vita.chen@naffreight.com": "2022-01-01T00:00:00",
  "hanni.chang@naffreight.com": "2022-01-01T00:00:00",
  "AARAO.TEIXIERA@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "shelane.chen@naffreight.com": "2022-01-01T00:00:00",
  "ANISS.JUMA@ALLWAYSCARGO.COM": "2020-02-01T00:00:00",
  "werner.florentnius@neelevat.com": "2020-02-01T00:00:00",
  "agi@neelevat.com": "2020-02-01T00:00:00",
  "networx.cs@networxglobal.net.ph": "2020-08-20T00:00:00",
  "mark.cabreros@networxglobal.net.ph": "2020-08-20T00:00:00",
  "accounting@networxglobal.net.ph": "2020-08-20T00:00:00",
  "dragos.vieru@ls-chartering.ro": "2020-02-01T00:00:00",
  "yokehwa.tine@sg.solexlogistics.com": "2020-02-01T00:00:00",
  "cody@nex3pl.com": "2024-05-01T00:00:00",
  "filip@nex3pl.com": "2024-05-01T00:00:00",
  "jacob@solutionpack.co.kr": "2023-09-01T00:00:00",
  "lauren@atraxlogistics.com": "2020-02-01T00:00:00",
  "arkadiusz@ocg.com": "2020-02-01T00:00:00",
  "zack.crlogistics@gmail.com": "2021-05-01T00:00:00",
  "import.pearljadelogistics@gmail.com": "2021-05-01T00:00:00",
  "imp.pearljadelogistics@gmail.com": "2021-05-01T00:00:00",
  "chongmac110@gmail.com": "2021-05-01T00:00:00",
  "i.carrero@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "bas.de.vaal@portside.ch": "2020-08-12T00:00:00",
  "d.fernandez@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "i.ochoa@cargoservicesgroup.com": "2020-02-01T00:00:00",
  "johan@lindqvisttrading.com": "2021-09-01T00:00:00",
  "montanaro@unilogistics.ch": "2024-01-01T00:00:00",
  "gloria.chu@naffreight.com": "2022-01-01T00:00:00",
  "ivan.poorten@cfglobal.co": "2023-08-01T00:00:00",
  "betina.gbadago@portside.ch": "2020-08-12T00:00:00",
  "anelson@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "lshenfield@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "jbrown@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "nparratt@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "snash@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "dstanden@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "jholmes@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "koonloon@sealandglobal.com.my": "2024-04-01T00:00:00",
  "deniz@cosulich.com.tr": "2020-04-13T00:00:00",
  "a.ognio@sinergy-italy.com": "2020-02-01T00:00:00",
  "cargo.air@sinergy-italy.com": "2020-02-01T00:00:00",
  "antonella@acline.italy.com": "2020-02-01T00:00:00",
  "senol@cosulich.com.tr": "2020-04-13T00:00:00",
  "seha@solibra.com.tr": "2020-02-01T00:00:00",
  "lilian.lau@sg.solexlogistics.com": "2020-02-01T00:00:00",
  "xavier.chang@sg.solexlogistics.com": "2020-02-01T00:00:00",
  "xavier.chang@solexlogistics.com": "2020-02-01T00:00:00",
  "funda@solibra.com.tr": "2020-02-01T00:00:00",
  "khaldoun@freight-app.com": "2023-03-01T00:00:00",
  "melih@solibra.com.tr": "2020-02-01T00:00:00",
  "oktavilgaz@solibra.com.tr": "2020-02-01T00:00:00",
  "irem@solibra.com.tr": "2020-02-01T00:00:00",
  "kubra.kaya@solibra.com.tr": "2020-02-01T00:00:00",
  "cuneyt@solibra.com.tr": "2020-02-01T00:00:00",
  "arif@solibra.com.tr": "2020-02-01T00:00:00",
  "zeynep.dogan@solibra.com.tr": "2020-02-01T00:00:00",
  "mehmet@solibra.com.tr": "2020-02-01T00:00:00",
  "enes@solibra.com.tr": "2020-02-01T00:00:00",
  "jane@solutionpack.co.kr": "2023-09-01T00:00:00",
  "joy@solutionpack.co.kr": "2023-09-01T00:00:00",
  "ella@solutionpack.co.kr": "2023-09-01T00:00:00",
  "acc@solutionpack.co.kr": "2023-09-01T00:00:00",
  "dbacon@riflogistics.co.uk": "2020-02-01T00:00:00",
  "rclear@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "rebbs@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "wgibson@rifworldwide.co.uk": "2020-02-01T00:00:00",
  "M.menck@gtslogistic.com": "2024-09-01T00:00:00",
  "dennis.spinning@talogis.de": "2020-02-01T00:00:00",
  "gsimonet@imperativelogistics.com": "2020-02-01T00:00:00",
  "Hudsonle@tpfs.com.vn": "2024-05-01T00:00:00",
  "marketing@tpfs.com.vn": "2024-05-01T00:00:00",
  "trang.nh@tpfs.com.vn": "2024-05-01T00:00:00",
  "eduardo@atoshipping.com": "2024-06-01T00:00:00",
  "tommuange@tmmlogistics.net": "2020-06-15T00:00:00",
  "tzabielski@imperativelogistics.com": "2020-02-01T00:00:00",
  "dominik.strittmatter@unilogistics.ch": "2024-01-01T00:00:00",
  "lukas.forman@unilogistics.ch": "2024-01-01T00:00:00",
  "carmen.brescia@unilogistics.ch": "2024-01-01T00:00:00",
  "cmiller@imperativelogistics.com": "2020-02-01T00:00:00",
  "ahmar@warecareinternational.com": "2024-08-01T00:00:00",
  "ajones@imperativelogistics.com": "2020-02-01T00:00:00",
  "carlos@nex3pl.com, worldring@nex3pl.com": "2024-05-01T00:00:00",
  "oversea.agent@greendragonlog.com.vn": "2024-03-01T00:00:00",
  "dngo@legendre.fr": "2023-10-05T00:00:00",
  "Fionapham@tpfs.com.vn": "2024-05-01T00:00:00",
};
