import { dashboardIcon, logoutIcon, profileIcon } from "../../../assets/icons";
import { CLEAR_PROPS } from "../../../store/actions/actionTypes";
import { store } from "../../../store/store";
import { urls } from "../../../services/router/utils/urls";
import { wp_url } from "../../../services/const";

export const _getWpToken = () => {
  const token = sessionStorage.getItem("wp-token");
  const email = sessionStorage.getItem("wp-email");
  return token && token !== "" ? `?token=${token}&email=${email}` : "";
};

export const getMenuSections = (userData) => {
  if (!userData) return [];

  const companies = userData.companies.map((company) => {
    return {
      title: company.name,
      link: company.wp_link,
    };
  });
  return [
    {
      title: "Work Bench",
      subSections: [
        { title: "Agent list", link: `${wp_url}/agent-list/` },
        {
          icon: "https://worldring.org/wp-content/uploads/icons/icon-copy.svg",
          title: "My Sales Page",
          link: `${userData.wp_sales_page_link}`,
        },

        { title: "Dashboard", link: urls.dashboard },

        { title: "Product search", link: urls.commoditiesSearch },
      ],
    },
    {
      title: "My company",
      subSections: [
        ...companies,
        {
          title: "Profile Administration",
          link: `${userData.wp_agent_profile_link}`,
        },
      ],
    },
    {
      title: "Resources",
      subSections: [
        { title: "(coming soon) Broadcast", link: ``, disabled: true },

        { title: "News", link: `${wp_url}/newsfeed` },
        {
          title: "Video/Training",
          link: `${wp_url}/video-training`,
        },
        { title: "Events", link: `${wp_url}/events` },
      ],
    },
  ];
};

export const getBottomSections = (userData) => {
  if (!userData) return [];

  return [
    {
      title: "My Profile",
      icon: profileIcon,
      link: `${userData.wp_user_profile_link}`,
    },
    { title: "My Dashboard", icon: dashboardIcon, link: urls.dashboard },
    {
      title: "Logout",
      icon: logoutIcon,
      link: "",
      onClick: logoutClick,
    },
  ];
};

export const sections = [
  {
    title: "Work Bench",
    subSections: [
      { title: "Dashboard", link: urls.dashboard },
      {
        icon: "https://worldring.org/wp-content/uploads/icons/icon-copy.svg",
        title: "My Sales Page",
        link: `${wp_url}/sales-page/${sessionStorage.getItem("wp-name")}`,
      },

      { title: "Agent list", link: `${wp_url}/agent-list/` },
      { title: "Product search", link: urls.commoditiesSearch },
    ],
  },
  {
    title: "My company",
    subSections: [
      {
        title: "Administration",
        link: `${wp_url}/agent-profile/${sessionStorage.getItem("wp-name")}`,
      },

      {
        title: "My Company Profile",
        link: `${wp_url}/company-profile/COMPANY_NAME`,
      },
    ],
  },
  {
    title: "Marketplace",
    subSections: [
      { title: "News", link: `${wp_url}/newsfeed#${_getWpToken()}` },
      {
        title: "Video/Training",
        link: `${wp_url}/video-training#${_getWpToken()}`,
      },
      { title: "Events", link: `${wp_url}/events#${_getWpToken()}` },
    ],
  },
];

const logoutClick = () => {
  store.dispatch({
    type: CLEAR_PROPS,
    payload: [],
  });
};

export const bottomSections = [
  {
    title: "My Profile",
    icon: profileIcon,
    link: `${wp_url}/user-profile/${sessionStorage.getItem("wp-name")}`,
  },
  { title: "My Dashboard", icon: dashboardIcon, link: urls.dashboard },
  {
    title: "Logout",
    icon: logoutIcon,
    link: "",
    onClick: logoutClick,
  },
];
