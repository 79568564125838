export const productColumns = [
  {
    Header: "Customer",
    accessor: "customer",
    sort: "",
  },

  {
    Header: "Type",
    accessor: "type",
    sort: "commodity_type_id",
  },
  {
    Header: "HSCode",
    accessor: "HSCode",
    sort: "product_id",
  },
  {
    Header: "Target Group",
    accessor: "target_group",
    sort: "target_group",
  },
  {
    Header: "Product Description",
    accessor: "product Description",
    sort: "description",
  },
  {
    Header: "Specification",
    accessor: "specification",
    sort: "specification",
  },
  {
    Header: "Country",
    accessor: "country",
    sort: "country",
  },
  {
    Header: "AIC",
    accessor: "AIC",
    sort: "",
  },
  {
    Header: "Membership Level",
    accessor: "Membership Level",
    sort: "",
  },
  {
    Header: "ID",
    accessor: "number",
    sort: "number",
  },
  {
    Header: "Online Since",
    accessor: "online since",
    sort: "created_at",
  },
  {
    Header: "Date of Match",
    accessor: "date of match",
    sort: "",
  },
];

export const userColumns = [
  {
    Header: "Company name",
    accessor: "company_name",
    sort: "",
  },

  {
    Header: "Country",
    accessor: "country",
    sort: "",
  },
  {
    Header: "City",
    accessor: "city",
    sort: "",
  },
  {
    Header: "AIC",
    accessor: "aic",
    sort: "",
    admin_only: true,
  },
  { Header: "User name", accessor: "user_name", sort: "name" },
  {
    Header: "Contact Email",
    accessor: "email",
    sort: "email",
  },
  {
    Header: "Contact Phone",
    accessor: "phone",
    sort: "phone",
  },
  {
    Header: "Membership Level",
    accessor: "user_level",
    sort: "user_level_id",
  },

  {
    Header: "Member since",
    accessor: "created_at",
    sort: "created_at",
  },
  {
    Header: "Membership expiry date",
    accessor: "expire_at",
    sort: "",
  },
  {
    Header: "Sent on",
    accessor: "sent_at",
    sort: "",
  },
  {
    Header: "Status",
    accessor: "status",
    sort: "",
  },
  {
    Header: "Time to expiry",
    accessor: "expireTime",
    sort: "",
  },
];
