import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { isResponseError } from "../../utils/axios";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";
import { useSelector } from "react-redux";

export const useGeMyCustomerEntriesProducts = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  commodity_type_id,
  country,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const currentUserLevel = useSelector((state) => state.login.level);

  const isAdmin = currentUserLevel === "admin";

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const { data } = await axios.get(`commodities`, {
        params: {
          per_page: perPage,
          page,
          commodity_type_id,
          country,
          search,
          user_level_id: userLevel ? [userLevel] : [1, 2, 3, 4, 5],
          is_only_pairings: false,
          my_related: isAdmin ? undefined : true,
          my: isAdmin ? true : undefined,
          order: sortList || undefined,
          columns_search: [
            "id",
            "name",
            "incoterm",
            "description",
            "country",
            "specification",
          ],
          with: [
            "user",
            "user.companies",
            "user.files",
            "user.role",
            "user.inviter",
          ],
        },
      });

      if (!isResponseError(data)) {
        const formattedData = data.data.map((commodity) => {
          const agentInCharge =
            commodity.user.inviter &&
            commodity.user.user_level.name !== "Trading Company"
              ? commodity.user.inviter.companies[0].name
              : "-";
          return {
            customer: `${
              commodity.user.companies[0].name
            }, ${commodity.user.inviter.companies[0].name.replace(
              "&#038;",
              "&"
            )}`,
            type: commodity.commodity_type.code,
            HSCode: commodity.product.code,
            "product Description": commodity.description,
            specification: commodity.specification,
            country: commodity.country,
            AIC: agentInCharge.replace("&#038;", "&"),
            "Membership Level": commodity.user.user_level
              ? commodity.user.user_level.name
              : "-",
            number: commodity.number,
            target_group: commodity.target_group || "-",

            id: commodity.id,
            "online since": formatDateToDDMMYYYY(commodity.created_at),
            "date of match": commodity.pairing_at
              ? formatDateToDDMMYYYY(commodity.pairing_at)
              : "-",
            user: commodity.user,
            is_belongs_to_me: commodity.is_belongs_to_me,
          };
        });

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;

        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    commodity_type_id,
    country,
    sortList,
    refetch,
    setRefetch,
    isAdmin,
  ]);

  return { data: data || [], isLoading, error, total: total || 0 };
};
