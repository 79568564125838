import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import customHistory from "../../services/history";
import { OptionsButton } from "./components/OptionsButton";
import { Popconfirm, Spin } from "antd";
import "./ant.scss";
import { useGetProducts } from "../../requests/products";
import { useGetCompanies } from "../../requests/company/useGetCompanies";
import { countries, statuses } from "./consts";
import NewInviteModal from "../../components/Jumpseat/NewInviteModal";
import ProductModal from "../../components/CompanyProfileModals/ServicesModal/ProductModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useGetInvitationCompanies } from "../../requests/company/useGetInvitationCompanies";
import { useGetMyMatchesProducts } from "../../requests/products/useGetMyMatchesProducts";
import { useGeMyEntriesProducts } from "../../requests/products/useGeMyEntriesProducts";
import { useGeMyCustomerEntriesProducts } from "../../requests/products/useGeMyCustomerEntriesProducts";

import { CreateSuperAgentUserModal } from "../../components/CreateSuperAgentUserModal";
import { UserSuccessfullyCreatedModal } from "../../components/UserSuccessfullyCreatedModal";
import AgentDashboardTable from "./components/Table";
import { typeFilter as types } from "../CommoditiesSearch";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { isResponseError } from "../../utils/axios";
import { useGetAgents } from "../../requests/company/useGetAgents";
import { CustomTabs } from "./CustomTabs";
import { FilterGroup } from "./FilterGroup";
import { threeDotsWhitePng } from "../../assets/icons";
import { Link } from "react-router-dom";

export const selectOptions = [
  { label: "User level", value: "" },
  { label: "Jumpseat", value: "1" },
  { label: "Economy", value: "2" },
  { label: "Business", value: "3" },
  { label: "First Class", value: "4" },
  { label: "Trading Company", value: "5" },
  { label: "Temp", value: "6" },
];

export const selectOptionsWithoutTemp = [
  { label: "User level", value: "" },
  { label: "Jumpseat", value: "1" },
  { label: "Economy", value: "2" },
  { label: "Business", value: "3" },
  { label: "First Class", value: "4" },
  { label: "Trading Company", value: "5" },
];

export const perPageOptions = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const AgentDashboard = () => {
  const [refetch, setRefetch] = useState(false);
  const [activeButtonId, setActiveButtonId] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDeleteModal, setIsDeleteModal] = useState(null);

  useEffect(() => {
    setSelectedCustomers([]);
  }, [activeButtonId]);

  const handleRemoveUsers = async () => {
    try {
      const deleteRequests = selectedCustomers.map((id) =>
        axios.delete(`/users/${id}`)
      );
      await Promise.all(deleteRequests);
      NotificationManager.success("Success");
      console.log("Все пользователи успешно удалены.");
      setRefetch(true);
    } catch (error) {
      console.error("Ошибка при удалении пользователей:", error);
      setRefetch(true);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [perPage]);

  const { push } = customHistory;

  const userGroup = useSelector((state) => state.login.group);
  const userLevel = useSelector((state) => state.login.level);

  const isAdmin = userLevel === "admin";
  const isAgent = userGroup === "agent";

  useEffect(() => {
    if (!isAgent && !isAdmin) {
      push("/");
    }
  }, [isAdmin, isAgent, push]);

  const [isUserCreateModalOpen, setIsUserCreateModalOpen] = useState(false);

  const [
    isUserSuccessfullyCreatedModalOpen,
    setIsUserSuccessfullyCreatedModalOpen,
  ] = useState(false);

  const [createdUserId, setCreatedUserId] = useState(0);
  const [createdUserinvitationLink, setCreatedUSerIinvitationLink] =
    useState("");

  const [isJSInvitesModalVisible, setIsJSInvitesModalVisible] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 200 });
  }, [activePage]);

  //-------------PRODUCTS BLOCK START----------
  //-------------PRODUCTS BLOCK START----------
  const [productSortList, setProductSortList] = useState();
  const [productSearchValue, setProductSearchValue] = useState("");
  const [productSearchValueForRequest, setProductSearchValueForRequest] =
    useState("");
  const [productSelectValue, setProductSelectValue] = useState("");
  const [productCountry, setProductCountry] = useState([]);
  const [productType, setProductType] = useState([]);

  const {
    data: productData,
    isLoading: isProductDataLoading,
    total: productTotal,
  } = useGetProducts({
    page: activePage,
    perPage,
    search: productSearchValueForRequest,
    sortList: productSortList,
    userLevel: productSelectValue,
    commodity_type_id: productType,
    country: productCountry,
    refetch: refetch,
    setRefetch: setRefetch,
    isAdmin,
  });
  //-------------PRODUCTS BLOCK END----------
  //-------------PRODUCTS BLOCK END----------

  //-------------MATCHES BLOCK START----------
  //-------------MATCHES BLOCK START----------
  const [myMatches_ProductCountry, setMyMatches_ProductCountry] = useState([]);
  const [myMatches_ProductType, setMyMatches_ProductType] = useState([]);
  const [myMatches_ProductsSearchValue, setMyMatches_ProductsSearchValue] =
    useState("");
  const [
    myMatches_ProductsSearchValueForRequest,
    setMyMatches_ProductsSearchValueForRequest,
  ] = useState("");
  const [myMatches_ProductsSelectValue, setMyMatches_ProductsSelectValue] =
    useState("");

  const [matchesSortList, setMatchesSortList] = useState();

  const {
    data: myMathes_productData,
    isLoading: isMyMathes_ProductDataLoading,
    total: myMathes_productTotal,
  } = useGetMyMatchesProducts({
    page: activePage,
    perPage,
    search: myMatches_ProductsSearchValueForRequest,
    sortList: matchesSortList,
    userLevel: myMatches_ProductsSelectValue,
    commodity_type_id: myMatches_ProductType,
    country: myMatches_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
    isAdmin,
  });
  //-------------MATCHES BLOCK END----------
  //-------------MATCHES BLOCK END----------

  //-------------CUSTOMERS BLOCK START----------
  //-------------CUSTOMERS BLOCK START----------
  const [companiesSortList, setCompaniesSortList] = useState({
    name: "asc",
  });
  const [companiesSearchValue, setCompaniesSearchValue] = useState("");
  const [companiesSearchValueForRequest, setCompaniesSearchValueForRequest] =
    useState("");
  const [companiesSelectValue, setCompaniesSelectValue] = useState("");

  const {
    data: companiesData,
    isLoading: isCompaniesDataLoading,
    total: companiesTotal,
  } = useGetCompanies({
    page: activePage,
    perPage,
    search: companiesSearchValueForRequest,
    sortList: companiesSortList,
    userLevel: companiesSelectValue,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------CUSTOMERS BLOCK END----------
  //-------------CUSTOMERS BLOCK END----------

  //-------------ADMIN AGENTS BLOCK START----------
  //-------------ADMIN AGENTS BLOCK START----------
  const [agentsSortList, setAgentsSortList] = useState();
  const [agentsSearchValue, setAgentsSearchValue] = useState("");
  const [agentsSearchValueForRequest, setAgentsSearchValueForRequest] =
    useState("");
  const [agentsSelectValue, setAgentsSelectValue] = useState("");

  const {
    data: agentsData,
    isLoading: isAgentsDataLoading,
    total: agentsTotal,
  } = useGetAgents({
    page: activePage,
    perPage,
    search: agentsSearchValueForRequest,
    sortList: agentsSortList,
    userLevel: agentsSelectValue,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------ADMIN AGENTS BLOCK END----------
  //-------------ADMIN AGENTS BLOCK END----------

  //-------------INVITATIONS BLOCK START----------
  //-------------INVITATIONS BLOCK START----------

  const [invitations_CompaniesStatus, setInvitations_CompaniesStatus] =
    useState([]);
  // const [
  //   invitations_CompaniesSearchValue,
  //   setInvitations_CompaniesSearchValue,
  // ] = useState("");
  const [
    invitations_CompaniesSearchValueForRequest,
    // setInvitations_CompaniesSearchValueForRequest,
  ] = useState("");
  const [
    invitations_CompaniesSelectValue,
    // setInvitations_CompaniesSelectValue,
  ] = useState("");
  const [invitesSortList, setInvitesSortLiest] = useState({
    company_name: "asc",
  });

  const {
    data: invitations_companiesData,
    isLoading: isInvitations_CompaniesDataLoading,
    total: invitations_companiesTotal,
  } = useGetInvitationCompanies({
    page: activePage,
    perPage,
    search: invitations_CompaniesSearchValueForRequest,
    sortList: invitesSortList,
    userLevel: invitations_CompaniesSelectValue,
    status: invitations_CompaniesStatus,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------INVITATIONS BLOCK END----------
  //-------------INVITATIONS BLOCK END----------

  //-------------MY ENTRIES BLOCK START----------
  //-------------MY ENTRIES BLOCK START----------
  const [myEntries_ProductCountry, setMyEntries_ProductCountry] = useState([]);
  const [myEntries_ProductType, setMyEntries_ProductType] = useState([]);

  const [myEntries_ProductsSearchValue, setMyEntries_ProductsSearchValue] =
    useState("");
  const [
    myEntries_ProductsSearchValueForRequest,
    setMyEntries_ProductsSearchValueForRequest,
  ] = useState("");
  const [myEntries_ProductsSelectValue, setMyEntries_ProductsSelectValue] =
    useState(6);
  const [entriesSortList, setEntriesSortList] = useState();

  const {
    data: myEntries_productData,
    isLoading: isMyEntries_ProductDataLoading,
    total: myEntries_productTotal,
  } = useGeMyEntriesProducts({
    page: activePage,
    perPage,
    search: myEntries_ProductsSearchValueForRequest,
    sortList: entriesSortList,
    userLevel: myEntries_ProductsSelectValue,
    commodity_type_id: myEntries_ProductType,
    country: myEntries_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------MY ENTRIES BLOCK END----------
  //-------------MY ENTRIES BLOCK END----------

  //-------------MY CUSTOMER ENTRIES BLOCK START----------
  //-------------MY CUSTOMER ENTRIES BLOCK START----------
  const [
    myCustomerEntries_ProductCountry,
    setMyCustomerEntries_ProductCountry,
  ] = useState([]);
  const [myCustomerEntries_ProductType, setMyCustomerEntries_ProductType] =
    useState([]);

  const [
    myCustomerEntries_ProductsSearchValue,
    setMyCustomerEntries_ProductsSearchValue,
  ] = useState("");
  const [
    myCustomerEntries_ProductsSearchValueForRequest,
    setMyCustomerEntries_ProductsSearchValueForRequest,
  ] = useState("");
  const [
    myCustomerEntries_ProductsSelectValue,
    setMyCustomerEntries_ProductsSelectValue,
  ] = useState("");
  const [myCustomerEntriesSortList, setMyCustomerEntriesSortList] = useState();

  const {
    data: myCustomerEntries_productData,
    isLoading: isMyCustomerEntries_ProductDataLoading,
    total: myCustomerEntries_productTotal,
  } = useGeMyCustomerEntriesProducts({
    page: activePage,
    perPage,
    search: myCustomerEntries_ProductsSearchValueForRequest,
    sortList: myCustomerEntriesSortList,
    userLevel: myCustomerEntries_ProductsSelectValue,
    commodity_type_id: myCustomerEntries_ProductType,
    country: myCustomerEntries_ProductCountry,
    refetch: refetch,
    setRefetch: setRefetch,
  });
  //-------------MY CUSTOMER ENTRIES BLOCK END----------
  //-------------MY CUSTOMER ENTRIES BLOCK END----------

  //-------------CUSTOMER ENTRIES BLOCK START----------
  //-------------CUSTOMER ENTRIES BLOCK START----------
  // const [customerEntries_ProductCountry, setCustomerEntries_ProductCountry] =
  //   useState([]);
  // const [customerEntries_ProductType, setCustomerEntries_ProductType] =
  //   useState([]);

  // const [
  //   customerEntries_ProductsSearchValue,
  //   setCustomerEntries_ProductsSearchValue,
  // ] = useState("");
  // const [
  //   customerEntries_ProductsSearchValueForRequest,
  //   setCustomerEntries_ProductsSearchValueForRequest,
  // ] = useState("");
  // const [
  //   customerEntries_ProductsSelectValue,
  //   setCustomerEntries_ProductsSelectValue,
  // ] = useState("");
  // const [customerEntriesSortList, setCustomerEntriesSortList] = useState();

  // const {
  //   data: customerEntries_productData,
  //   isLoading: isCustomerEntries_ProductDataLoading,
  //   total: customerEntries_productTotal,
  // } = useGeMyEntriesProducts({
  //   page: activePage,
  //   perPage,
  //   search: customerEntries_ProductsSearchValueForRequest,
  //   sortList: customerEntriesSortList,
  //   userLevel: customerEntries_ProductsSelectValue,
  //   commodity_type_id: customerEntries_ProductType,
  //   country: customerEntries_ProductCountry,
  //   refetch: refetch,
  //   setRefetch: setRefetch,
  // });
  //-------------CUSTOMER ENTRIES BLOCK END----------
  //-------------CUSTOMER ENTRIES BLOCK END----------

  useEffect(() => {
    setActivePage(1);
  }, [
    activeButtonId,
    productSearchValue,
    myMatches_ProductsSearchValue,
    companiesSearchValue,
    agentsSearchValue,
    invitations_CompaniesSearchValueForRequest,
    myEntries_ProductsSearchValue,
  ]);

  const tabs = [
    {
      key: "products",
      label: "Products",
      count: isProductDataLoading ? <Spin /> : productTotal | 0,
      id: 1,
    },
    {
      key: "agents",
      label: "Agents",
      count: isAgentsDataLoading ? <Spin /> : agentsTotal | 0,
      id: 6,
      adminOnly: true,
    },
    {
      key: "customers",
      label: "Customers",
      count: isCompaniesDataLoading ? <Spin /> : companiesTotal | 0,
      id: 2,
    },
    {
      key: "invitations",
      label: "Invitations",
      count: isInvitations_CompaniesDataLoading ? (
        <Spin />
      ) : (
        invitations_companiesTotal | 0
      ),
      id: 3,
    },
    {
      key: "matches",
      label: "Matches",
      count: isMyMathes_ProductDataLoading ? (
        <Spin />
      ) : (
        myMathes_productTotal | 0
      ),
      id: 4,
    },
    {
      key: "myEntries",
      label: "My entries",
      count: isMyEntries_ProductDataLoading ? (
        <Spin />
      ) : (
        myEntries_productTotal | 0
      ),
      id: 5,
    },
    {
      key: "customerEntries",
      label: "My Customer entries",
      count: isMyCustomerEntries_ProductDataLoading ? (
        <Spin />
      ) : (
        myCustomerEntries_productTotal | 0
      ),
      id: 7,
      agentOnly: true,
    },
  ];

  const onClickUploadPnS = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);

    axios
      .post("/commodities", formData)
      .then((ret) => {
        if (!isResponseError(ret)) {
          NotificationManager.success("HSCodes uploaded successfully");
        }
      })
      .catch((err) => {
        NotificationManager.error("Something went wrong, please try again.");
      });
    e.target.value = null;
  };

  return (
    <div className={styles.wrapper}>
      <h1>MY DASHBOARD</h1>
      <NewInviteModal
        show={isJSInvitesModalVisible}
        onHide={() => {
          setIsJSInvitesModalVisible(false);
          setRefetch(true);
        }}
      />
      {isProductModalVisible && (
        <ProductModal
          show={isProductModalVisible}
          onHide={() => {
            setIsProductModalVisible(false);
            setRefetch(true);
          }}
          clientHSCodeModal={true}
        />
      )}
      {isUserCreateModalOpen && (
        <CreateSuperAgentUserModal
          setCreatedUserId={setCreatedUserId}
          setCreatedUSerIinvitationLink={setCreatedUSerIinvitationLink}
          isOpen={isUserCreateModalOpen}
          onClose={() => {
            setIsUserCreateModalOpen(false);
            setRefetch(true);
          }}
          setIsUserSuccessfullyCreatedModalOpen={
            setIsUserSuccessfullyCreatedModalOpen
          }
        />
      )}
      {isUserSuccessfullyCreatedModalOpen && (
        <UserSuccessfullyCreatedModal
          invitationLink={createdUserinvitationLink}
          userId={createdUserId}
          isOpen={isUserSuccessfullyCreatedModalOpen}
          onClose={() => setIsUserSuccessfullyCreatedModalOpen(false)}
        />
      )}

      <div
        style={{
          width: "96%",
          marginBottom: 40,
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        {isAdmin &&
          (activeButtonId === 2 || activeButtonId === 6) &&
          !!selectedCustomers.length && (
            <Popconfirm
              title="Delete the users"
              description="Are you sure to delete all selected users?"
              onConfirm={handleRemoveUsers}
              okText="Yes"
              cancelText="No"
            >
              <button className={styles.inviteButton}>Delete</button>
            </Popconfirm>
          )}
        <OptionsButton
          clickContainer={
            <button className={styles.inviteButton}>
              <span className={styles.plusText}>+</span>
              <span>New</span>
              <img
                src={threeDotsWhitePng}
                alt="threeDotsWhite"
                style={{ cursor: "pointer", width: 22, height: 25 }}
              />
            </button>
          }
        >
          <div
            className={styles.inviteButtonContainer}
            style={{
              whiteSpace: "nowrap",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              gap: "9px",
            }}
          >
            <div
              onClick={() => setIsJSInvitesModalVisible(true)}
              className={styles.inviteSubButton}
              style={{
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
                color: "white",
                background: "orange",
              }}
            >
              Jumpseat Invitation
            </div>
            <div
              onClick={() => setIsProductModalVisible(true)}
              className={styles.inviteSubButton}
              style={{
                cursor: "pointer",
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
                color: "white",
                background: "orange",
              }}
            >
              Create Product Entry
            </div>
            <div
              style={{
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
                color: "white",
                background: "orange",
              }}
              className={styles.inviteSubButton}
            >
              <label style={{ cursor: "pointer", marginBottom: 0 }}>
                <span style={{ color: "#fff" }}>Product Mass Upload</span>
                <input
                  type="file"
                  className="d-none"
                  accept=".csv, .xls, .xlsx, .numbers"
                  onChange={onClickUploadPnS}
                />
                <FontAwesomeIcon className="ml-2" icon={faUpload} />
              </label>
            </div>

            <Link
              style={{
                border: "1px solid orange",
                padding: "5px 10px",
                borderRadius: "10px",
                color: "white",
                background: "orange",
              }}
              className={styles.inviteSubButton}
              to="/wr-massupload-template_V5b.xlsx"
              target="_blank"
              download
            >
              <span style={{ color: "#fff" }}>Sample upload file</span>
              <FontAwesomeIcon
                style={{ transform: "rotate(180deg)" }}
                className="ml-2"
                icon={faUpload}
              />
            </Link>

            {isAdmin && (
              <div
                className={styles.inviteSubButton}
                style={{
                  border: "1px solid orange",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  color: "white",
                  background: "orange",
                }}
                onClick={() => setIsUserCreateModalOpen(true)}
              >
                <span>Add Superagent</span>
                <span></span>
              </div>
            )}
          </div>
        </OptionsButton>
      </div>
      <div className={styles.container}>
        <div className={styles.sectionsLine}>
          <CustomTabs
            items={tabs}
            setActiveButtonId={setActiveButtonId}
            isAdmin={isAdmin}
          />
        </div>
        <div className={styles.main}>
          {activeButtonId === 1 && (
            <>
              <FilterGroup
                selectValue={productSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setProductSelectValue}
                searchValue={productSearchValue}
                setSearchValueForRequst={setProductSearchValueForRequest}
                setSearchValue={setProductSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: productCountry,
                    setThreeIcons: setProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: productType,
                    setThreeIcons: setProductType,
                  },
                ]}
                sortList={productSortList}
                setSortList={setProductSortList}
                total={productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={productData}
                isLoading={isProductDataLoading}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 2 && (
            <>
              <FilterGroup
                selectValue={companiesSelectValue}
                selectOptions={selectOptionsWithoutTemp}
                setSelectValue={setCompaniesSelectValue}
                searchValue={companiesSearchValue}
                setSearchValueForRequst={setCompaniesSearchValueForRequest}
                setSearchValue={setCompaniesSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[]}
                sortList={companiesSortList}
                setSortList={setCompaniesSortList}
                total={companiesTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={companiesData}
                isLoading={isCompaniesDataLoading}
                setRefetch={setRefetch}
                setSelectedCustomers={setSelectedCustomers}
              />
            </>
          )}

          {activeButtonId === 6 && (
            <>
              <FilterGroup
                selectValue={agentsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setAgentsSelectValue}
                searchValue={agentsSearchValue}
                setSearchValueForRequst={setAgentsSearchValueForRequest}
                setSearchValue={setAgentsSearchValue}
                styles={styles}
                onlySearch
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[]}
                sortList={agentsSortList}
                setSortList={setAgentsSortList}
                total={agentsTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={agentsData}
                isLoading={isAgentsDataLoading}
                setRefetch={setRefetch}
                setSelectedCustomers={setSelectedCustomers}
              />
            </>
          )}

          {activeButtonId === 3 && (
            <>
              {/* <FilterGroup
                selectValue={invitations_CompaniesSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setInvitations_CompaniesSelectValue}
                searchValue={invitations_CompaniesSearchValue}
                setSearchValueForRequst={
                  setInvitations_CompaniesSearchValueForRequest
                }
                setSearchValue={setInvitations_CompaniesSearchValue}
                styles={styles}
              /> */}
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[
                  {
                    columnName: "Status",
                    threeIconsOptions: statuses,
                    threeIconsValue: invitations_CompaniesStatus,
                    setThreeIcons: setInvitations_CompaniesStatus,
                  },
                ]}
                isInvitationsTab={true}
                total={invitations_companiesTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={false}
                data={invitations_companiesData}
                isLoading={isInvitations_CompaniesDataLoading}
                sortList={invitesSortList}
                setSortList={setInvitesSortLiest}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 4 && (
            <>
              <FilterGroup
                selectValue={myMatches_ProductsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setMyMatches_ProductsSelectValue}
                searchValue={myMatches_ProductsSearchValue}
                setSearchValueForRequst={
                  setMyMatches_ProductsSearchValueForRequest
                }
                setSearchValue={setMyMatches_ProductsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: myMatches_ProductCountry,
                    setThreeIcons: setMyMatches_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: myMatches_ProductType,
                    setThreeIcons: setMyMatches_ProductType,
                  },
                ]}
                total={myMathes_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={myMathes_productData}
                isLoading={isMyMathes_ProductDataLoading}
                sortList={matchesSortList}
                setSortList={setMatchesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}
          {activeButtonId === 5 && (
            <>
              <FilterGroup
                selectValue={myEntries_ProductsSelectValue}
                selectOptions={selectOptions}
                setSelectValue={setMyEntries_ProductsSelectValue}
                searchValue={myEntries_ProductsSearchValue}
                setSearchValueForRequst={
                  setMyEntries_ProductsSearchValueForRequest
                }
                setSearchValue={setMyEntries_ProductsSearchValue}
                styles={styles}
                onlySearch={true}
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: myEntries_ProductCountry,
                    setThreeIcons: setMyEntries_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: myEntries_ProductType,
                    setThreeIcons: setMyEntries_ProductType,
                  },
                ]}
                total={myEntries_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={myEntries_productData}
                isLoading={isMyEntries_ProductDataLoading}
                sortList={entriesSortList}
                setSortList={setEntriesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}

          {activeButtonId === 7 && !isAdmin && (
            <>
              <FilterGroup
                selectValue={myCustomerEntries_ProductsSelectValue}
                selectOptions={selectOptionsWithoutTemp}
                setSelectValue={setMyCustomerEntries_ProductsSelectValue}
                searchValue={myCustomerEntries_ProductsSearchValue}
                setSearchValueForRequst={
                  setMyCustomerEntries_ProductsSearchValueForRequest
                }
                setSearchValue={setMyCustomerEntries_ProductsSearchValue}
                styles={styles}
              />
              <AgentDashboardTable
                perPage={perPage}
                setPerPage={setPerPage}
                threeIconsData={[
                  {
                    columnName: "Country",
                    threeIconsOptions: countries,
                    threeIconsValue: myCustomerEntries_ProductCountry,
                    setThreeIcons: setMyCustomerEntries_ProductCountry,
                  },
                  {
                    columnName: "Type",
                    threeIconsOptions: types,
                    threeIconsValue: myCustomerEntries_ProductType,
                    setThreeIcons: setMyCustomerEntries_ProductType,
                  },
                ]}
                total={myCustomerEntries_productTotal}
                setActivePage={setActivePage}
                activePage={activePage}
                isProductPage={true}
                data={myCustomerEntries_productData}
                isLoading={isMyCustomerEntries_ProductDataLoading}
                sortList={myCustomerEntriesSortList}
                setSortList={setMyCustomerEntriesSortList}
                setRefetch={setRefetch}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
